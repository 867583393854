import * as React from 'react'

const CookiePolicy = () => {
    return (
        <div id="content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-muted">Definitions and key terms</h3>
                  <p className="text-muted">
                    To help explain things as clearly as possible in this Cookie
                    Policy, every time any of these terms are referenced, are
                    strictly defined as:
                  </p>

                  <ul className="text-muted">
                    <li>
                      Cookie: small amount of data generated by a website and
                      saved by your web browser. It is used to identify your
                      browser, provide analytics, remember information about you
                      such as your language preference or login information.
                    </li>
                    <li>
                      Company: when this policy mentions “Company,” “we,” “us,”
                      or “our,” it refers to Advocacy.biz by Capitol Enquiry,
                      that is responsible for your information under this Cookie
                      Policy.
                    </li>
                    <li>
                      Device: any internet connected device such as a phone,
                      tablet, computer or any other device that can be used to
                      visit Advocacy.biz by Capitol Enquiry and use the
                      services.
                    </li>
                    <li>
                      Personal Data: any information that directly, indirectly,
                      or in connection with other information — including a
                      personal identification number — allows for the
                      identification or identifiability of a natural person.
                    </li>
                    <li>
                      Service: refers to the service provided by Advocacy.biz by
                      Capitol Enquiry as described in the relative terms (if
                      available) and on this platform.
                    </li>
                    <li>
                      Third-party service: refers to advertisers, contest
                      sponsors, promotional and marketing partners, and others
                      who provide our content or whose products or services we
                      think may interest you.
                    </li>
                    <li>
                      Website: Advocacy.biz by Capitol Enquiry’s site, which can
                      be accessed via this URL: https://www.advocacy.biz
                    </li>
                    <li>
                      You: a person or entity that is registered with
                      Advocacy.biz by Capitol Enquiry to use the Services.
                    </li>
                  </ul>
                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Introduction</h3>
                  <p className="text-muted">
                    This Cookie Policy explains how Advocacy.biz by Capitol
                    Enquiry and its affiliates (collectively "Advocacy.biz by
                    Capitol Enquiry", "we", "us", and "ours"), use cookies and
                    similar technologies to recognize you when you visit our
                    website, including without limitation
                    https://www.advocacy.biz and any related URLs, mobile or
                    localized versions and related domains / sub-domains
                    ("Websites"). It explains what these technologies are and
                    why we use them, as well as the choices for how to control
                    them.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">What is a cookie?</h3>
                  <p className="text-muted">
                    A cookie is a small text file that is stored on your
                    computer or other internet connected device in order to
                    identify your browser, provide analytics, remember
                    information about you such as your language preference or
                    login information. They're completely safe and can't be used
                    to run programs or deliver viruses to your device.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Why do we use cookies?</h3>
                  <p className="text-muted">
                    We use first party and/or third party cookies on our website
                    for various purposes such as:
                  </p>

                  <ul className="text-muted">
                    <li>
                      To facilitate the operation and functionality of our
                      website;
                    </li>
                    <li>
                      To improve your experience of our website and make
                      navigating around them quicker and easier;
                    </li>
                    <li>
                      To allow us to make a bespoke user experience for you and
                      for us to understand what is useful or of interest to you;
                    </li>
                    <li>
                      To analyze how our website is used and how best we can
                      customize it;
                    </li>
                    <li>
                      To identify future prospects and personalize marketing and
                      sales interactions with it;
                    </li>
                    <li>
                      To facilitate the tailoring of online advertising to your
                      interests.
                    </li>
                  </ul>
                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">
                    What type of cookies does Advocacy.biz by Capitol Enquiry
                    use?
                  </h3>
                  <p className="text-muted">
                    Cookies can either be session cookies or persistent cookies.
                    A session cookie expires automatically when you close your
                    browser. A persistent cookie will remain until it expires or
                    you delete your cookies. Expiration dates are set in the
                    cookies themselves; some may expire after a few minutes
                    while others may expire after multiple years. Cookies placed
                    by the website you’re visiting are called “first party
                    cookies”.
                  </p>
                  <p className="text-muted">
                    Strictly Necessary cookies are necessary for our website to
                    function and cannot be switched off in our systems. They are
                    essential in order to enable you to navigate around the
                    website and use its features. If you remove or disable these
                    cookies, we cannot guarantee that you will be able to use
                    our website.
                  </p>
                  <p className="text-muted">
                    We use the following types of cookies in our website:
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Essential Cookies</h3>
                  <p className="text-muted">
                    We use essential cookies to make our website work. These
                    cookies are strictly necessary to enable core functionality
                    such as security, network management, your cookie
                    preferences and accessibility. Without them you wouldn't be
                    able to use basic services. You may disable these by
                    changing your browser settings, but this may affect how the
                    Websites function.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">
                    Performance and Functionality Cookies
                  </h3>
                  <p className="text-muted">
                    These cookies are used to enhance the performance and
                    functionality of our website but are non-essential to their
                    use. However, without these cookies, certain functionality
                    like videos may become unavailable or you would be required
                    to enter your login details every time you visit the website
                    as we would not be able to remember that you had logged in
                    previously.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Marketing Cookies</h3>
                  <p className="text-muted">
                    These account-based marketing cookies enable us to identify
                    future prospects and personalize sales and marketing
                    interactions with them.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">
                    Analytics and Customization Cookies
                  </h3>
                  <p className="text-muted">
                    These cookies collect information that is used to help us
                    understand how our website is being used or how effective
                    our marketing campaigns are, or to help us customize our
                    website for you.
                  </p>
                  <p className="text-muted">
                    We use cookies served by Google Analytics to collect limited
                    data directly from end-user browsers to enable us to better
                    understand your use of our website. Further information on
                    how Google collects and uses this data can be found
                    at: https://www.google.com/policies/privacy/partners/. You
                    can opt-out of all Google supported analytics on our
                    Websites by
                    visiting: https://tools.google.com/dlpage/gaoptout.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Social Media Cookies</h3>
                  <p className="text-muted">
                    These cookies are used when you share information using a
                    social media sharing button or “like” button on our Site or
                    you link your account or engage with our content on or
                    through a social networking website such as Facebook,
                    Twitter or Google+. The social network will record that you
                    have done this. These cookies may also include certain code
                    that has been placed on the platform to help track
                    conversions from ads, optimize ads based on collected data,
                    build targeted audiences for future ads, and remarket to
                    qualified users who have already taken certain action on the
                    platform.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Third Party Cookies</h3>
                  <p className="text-muted">
                    Some cookies that have been set on our website are not set
                    on a first party basis by Advocacy.biz by Capitol Enquiry.
                    The Websites can be embedded with content from third parties
                    to serve advertising. These third party service providers
                    may set their own cookies on your web browser. Third party
                    service providers control many of the performance and
                    functionality, advertising, marketing and analytics cookies
                    described above. We do not control the use of these third
                    party cookies as cookies can only be accessed by the third
                    party that originally set them.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">How you can manage cookies?</h3>
                  <p className="text-muted">
                    Most browsers allow you to control cookies through their
                    'settings' preferences. However, if you limit the ability of
                    websites to set cookies, you may worsen your overall user
                    experience, since it will no longer be personalized to you.
                    It may also stop you from saving customized settings like
                    login information. Browser manufacturers provide help pages
                    relating to cookie management in their products.
                  </p>
                  <p className="text-muted">
                    Browser manufacturers provide help pages relating to cookie
                    management in their products. Please see below for more
                    information.
                  </p>

                  <ul className="text-muted">
                    <li>Google Chrome</li>
                    <li>Internet Explorer</li>
                    <li>Mozilla Firefox</li>
                    <li>Safari (Desktop)</li>
                    <li>Safari (Mobile)</li>
                    <li>Android Browser</li>
                    <li>Opera</li>
                    <li>Opera Mobile</li>
                  </ul>
                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">
                    Blocking and disabling cookies and similar technologies
                  </h3>
                  <p className="text-muted">
                    Wherever you're located you may also set your browser to
                    block cookies and similar technologies, but this action may
                    block our essential cookies and prevent our website from
                    functioning properly, and you may not be able to fully
                    utilize all of its features and services. You should also be
                    aware that you may also lose some saved information (e.g.
                    saved login details, site preferences) if you block cookies
                    on your browser. Different browsers make different controls
                    available to you. Disabling a cookie or category of cookie
                    does not delete the cookie from your browser, you will need
                    to do this yourself from within your browser, you should
                    visit your browser's help menu for more information.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Changes To Our Cookie Policy</h3>
                  <p className="text-muted">
                    We may change our Service and policies, and we may need to
                    make changes to this Cookie Policy so that they accurately
                    reflect our Service and policies. Unless otherwise required
                    by law, we will notify you (for example, through our
                    Service) before we make changes to this Cookie Policy and
                    give you an opportunity to review them before they go into
                    effect. Then, if you continue to use the Service, you will
                    be bound by the updated Cookie Policy. If you do not want to
                    agree to this or any updated Cookie Policy, you can delete
                    your account.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Your Consent</h3>
                  <p className="text-muted">
                    By using our website, registering an account, or making a
                    purchase, you hereby consent to our Cookie Policy and agree
                    to its terms.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Contact Us</h3>
                  <p className="text-muted">
                    Don't hesitate to contact us if you have any questions
                    regarding our Cookie Policy.
                  </p>

                  <ul className="text-muted">
                    <li>Via Email: info@advocacy.biz</li>
                    <li>Via Phone Number: 9163020000</li>
                    <li>
                      Via this Address: 1034 Emerald Bay Rd #435, South Lake
                      Tahoe, CA 96150
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    )
}

export default CookiePolicy
